import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './landingpagestyle.module.css';

function LandingPage() {
    const [events, setEvents] = useState([]);

    const handleEventCall = (id) => {
        Cookies.set('id', id, { expires: 7 });
        window.location.href = `/register/${id}`;
    };

    const getEvents = async () => {
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };
        await fetch("https://services.uol.edu.pk/api/get-events", requestOptions)
            .then((response) => response.json())
            .then((data) => {
                setEvents(data.events);
                console.log('Data: ', data.events);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        getEvents();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: Math.min(events.length, 3),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(events.length, 2),
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className={styles.landingPageBackground}>
                <div className="navigation position-absolute w-100 rounded-bottom-3 rounded-bottom-sm-4">
                    <nav className="navbar navbar-expand-xl px-2" aria-label="Offcanvas navbar large">
                        <div className="container px-3 py-2 d-flex justify-content-between align-items-center">
                            <div className={styles.navbarBrandContainer}>
                                <a className="navbar-brand p-1" href="/">
                                    <img src="/images/uol_logo_new.png" className={styles.logo} alt="logo" />
                                </a>
                            </div>
                            <div className="offcanvas offcanvas-bottom rounded-top-5 h-auto" tabIndex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
                                <div className="offcanvas-header px-4 pt-4 pb-4">
                                    <h5 className="offcanvas-title m-0" id="offcanvasNavbar2Label">
                                        <a className="navbar-brand px-2 py-1" href="javascript:;">
                                            <img src="./img/bg/bg1.jpg" height="32" alt="logo" />
                                        </a>
                                    </h5>
                                    <button type="button" className="btn-close text-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div id="home" className="d-flex align-items-center position-relative">
                    <div className="container">
                        <div className="py-8 py-md-9 py-xl-11">
                            <div className="row gy-5 align-items-center justify-content-between">
                                <div className="col-12 col-xl-6 text-center text-xl-start order-xl-last">
                                    <div className="max-w-2xl mx-auto mx-xl-0 py-5 py-xl-0">
                                        <h1 className="m-0 mt-4 text-body-emphasis fw-bold tracking-tight text-6xl" data-aos-delay="0" data-aos="fade" data-aos-duration="1000">
                                            UOL Conferences & Events Registration
                                        </h1>
                                        <p className="m-0 mt-4 text-lg leading-8 text-body-secondary" data-aos-delay="100" data-aos="fade" data-aos-duration="1000">
                                            Join us for an unforgettable event – register now and secure your spot!
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-5" data-aos-delay="0" data-aos="fade" data-aos-duration="1000">
                                    <div className="max-w-2xl mx-auto mx-xl-0">
                                        <div className="ratio" style={{ '--bs-aspect-ratio': '76.66%' }}>
                                            <img src="./img/bg/bg1.jpg" className="img-fluid object-fit-cover rounded-3 rounded-sm-4 rounded-xl-5" alt="People talking" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="registrationpath" className="overflow-hidden py-7 py-sm-8 py-xl-9">
                    <div className="container">
                        <div>
                            <div className="mx-auto max-w-2xl text-center">
                                <p className="m-0 mt-2 text-body-emphasis text-4xl tracking-tight fw-bold">
                                    Registration Path
                                </p>
                                <p className="m-0 mt-4 text-body text-lg leading-8">
                                    Follow the Path to Success - Register Now and Start Your Journey!
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className="row row-cols-1 row-cols-xl-3 gy-5 gx-xl-4 mt-1 justify-content-center justify-content-xl-between">
                                <div className="col pt-5 pt-xl-4">
                                    <div className="max-w-xl mx-auto mx-xl-0" data-aos-delay="0" data-aos="fade" data-aos-duration="1000">
                                        <h3
                                            className="m-0 mt-4 text-body-emphasis text-lg leading-6 fw-semibold"
                                            style={{
                                                backgroundColor: '#8dbc9d', // Light blue background
                                                padding: '10px', // Add padding around the text
                                                borderRadius: '4px', // Optional: Rounded corners
                                            }}
                                        >
                                            Apply
                                        </h3>
                                        <p className="m-0 mt-3 text-body-secondary text-sm leading-6">
                                            To register for the event, candidates must complete the registration form with the required details and pay the entry fee upon receiving a confirmation email and fee voucher sent to the provided email address.
                                        </p>
                                    </div>
                                </div>

                                <div className="col pt-5 pt-xl-4">
                                    <div className="max-w-xl mx-auto mx-xl-0" data-aos-delay="100" data-aos="fade" data-aos-duration="1000">
                                        <h3
                                            className="m-0 mt-4 text-body-emphasis text-lg leading-6 fw-semibold"
                                            style={{
                                                backgroundColor: '#8dbc9d', // Light blue background
                                                padding: '10px', // Add padding around the text
                                                borderRadius: '4px', // Optional: Rounded corners
                                            }}
                                        >
                                            Pay
                                        </h3>
                                        <p className="m-0 mt-3 text-body-secondary text-sm leading-6">
                                            Please make payment for the voucher at your earliest convenience to complete your registration for the event. Registration Details: Pay Now & Download Voucher.
                                        </p>
                                    </div>
                                </div>

                                <div className="col pt-5 pt-xl-4">
                                    <div className="max-w-xl mx-auto mx-xl-0" data-aos-delay="200" data-aos="fade" data-aos-duration="1000">
                                        <h3
                                            className="m-0 mt-4 text-body-emphasis text-lg leading-6 fw-semibold"
                                            style={{
                                                backgroundColor: '#8dbc9d', // Light blue background
                                                padding: '10px', // Add padding around the text
                                                borderRadius: '4px', // Optional: Rounded corners
                                            }}
                                        >
                                            Attend
                                        </h3>
                                        <p className="m-0 mt-3 text-body-secondary text-sm leading-6">
                                            Passes will be sent to candidates via email two days prior to the event.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div id="services" className="overflow-hidden py-3 py-sm-8 py-xl-9">
                    <div className="container">
                        <div>
                            <div className="mx-auto max-w-2xl text-center">
                                <p className="m-0 mt-2 text-body-emphasis text-4xl tracking-tight fw-bold">
                                    Ongoing Events
                                </p>
                                <p className="m-0 mt-4 text-body text-lg leading-8">

                                </p>
                            </div>
                        </div>
                        <Slider {...settings}>
                            {
                                events.map((event, index) => (
                                    <div key={index}>
                                        <div className="max-w-xl mx-auto mx-xl-0" data-aos-delay="0" data-aos="fade" data-aos-duration="1000">
                                            <div className="ratio" style={{ '--bs-aspect-ratio': '66.66%' }}>
                                                <img src={`https://services.uol.edu.pk/attachments/${event.event_image}`} className="object-fit-cover rounded-3 rounded-sm-4 rounded-xl-5 p-3" alt="Service image" loading="lazy" />
                                            </div>
                                            <h3 className="m-0 mt-4 text-body-emphasis text-lg leading-6 fw-semibold">
                                                {event.event_title}
                                            </h3>
                                            <p className="m-0 mt-3 text-body-secondary line-clamp-2 text-sm leading-6">
                                                Date: {event.event_date}
                                            </p>
                                            <div className="mt-4 pt-3 text-center">
                                                <a href="#" className="btn btn-lg btn-secondary text-white text-sm fw-semibold" onClick={(e) => { e.preventDefault(); handleEventCall(event.id); }}>
                                                    Join Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </div>



                <div className="pb-9 pt-7">
                    <div className="container">
                        <div className="py-6 position-relative text-white rounded-3 rounded-sm-4 rounded-xl-5 shadow">
                            <img
                                src="./img/bg/bg10.jpg"
                                className="position-absolute z-0 top-0 h-100 w-100 object-fit-cover rounded-3 rounded-sm-4 rounded-xl-5"
                                loading="lazy"
                                alt="Meeting"
                            />
                            <div
                                className="position-absolute z-1 top-0 h-100 w-100 bg-dark rounded-3 rounded-sm-4 rounded-xl-5"
                                style={{
                                    opacity: 0.85,
                                    mixBlendMode: 'multiply',
                                    filter: 'contrast(1.15) brightness(0.85)',
                                }}
                            />
                            <div className="px-6 position-relative z-2">
                                <div className="mx-auto max-w-2xl">
                                    <h2 className="m-0 tracking-tight text-4xl fw-bold text-center">
                                        Explore more with UOL?
                                    </h2>
                                    <p className="m-0 mt-4 text-lg leading-8 text-center">
                                        Connect with us today.
                                    </p>
                                    <div className="mt-4 pt-3 text-center">
                                        <a href="https://uol.edu.pk/" className="btn btn-lg btn-primary text-white text-sm fw-semibold">
                                            Get Started
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <hr className="my-2 text-body-emphasis opacity-10" />
                </div>
                <footer className="container py-4">
                    <div className="d-flex flex-column flex-md-row gap-4 justify-content-md-between align-items-md-center">
                        <p className="text-body-tertiary text-xs leading-5 mb-0">
                            ©
                            <span className="current-year"></span>
                            Biafotech Pvt Ltd. All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default LandingPage;
