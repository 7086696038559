import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./Register";
import { routes } from "./routes.js";
import Home from "./Home";
import NotFound from "./NotFound.js";
import LandingPage from "./LandingPage.js";

export default function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/register/:id" element={<Register />}></Route>
          <Route path="/" element={<LandingPage />}></Route>
        </Routes>
      </Router>
    </>
  );
}